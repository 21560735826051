.container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.container1 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: flex-start;
  margin-top: 5px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 5px;
  padding-right: 10px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #D9D9D9;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.image {
  width: 30px;
  margin-top: 10px;
  object-fit: cover;
  margin-right: 30px;
  margin-bottom: 10px;
}
