.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #565656;
}
.container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 50px;
  align-items: flex-start;
}
.image {
  flex: 0;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
